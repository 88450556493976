<template>
  <a-modal
    :visible="showSetupTrainingModal"
    title="Setup Training"
    :mask-closable="false"
    width="45vw"
    :body-style="{ overflowY: 'auto' }"
    :destroy-on-close="true"
    @cancel="resetSetupTrainingModal"
  >
    <template #footer>
      <a-button
        type="primary"
        :loading="requestTrainingLoading"
        @click="handleSetupTraining"
      >
        Request Training
      </a-button>
    </template>
    <a-tabs v-model:activeKey="activeKey">
      <!-- Setup Training Tab -->
      <a-tab-pane key="1" tab="Model Configuration" force-render>
        <a-card title="Yolo Version" size="small" hoverable>
          <a-select
            v-model:value="selectedYoloVersion"
            class="w-100"
            :options="filteredYoloVersionOptions"
          />
        </a-card>
        <a-card title="Yolo Variant" size="small" hoverable class="mt-1">
          <a-select
            v-model:value="selectedYoloVariant"
            class="w-100"
            :options="yoloVariantOptions"
          />
        </a-card>
        <a-card title="Description" size="small" hoverable class="mt-1">
          <a-textarea v-model:value="trainingDescription" />
        </a-card>
      </a-tab-pane>

      <!-- Training Parameters Tab-->
      <a-tab-pane
        key="2"
        tab="Training Configuration"
        style="height: 60vh; overflow-y: auto"
      >
        <a-card title="Training Parameters" size="small" hoverable>
          <a-card title="Batch Size" size="small" hoverable class="mt-1">
            <a-input-number
              v-model:value="trainingParams.batchSize"
              class="w-100"
              :min="0"
            />
          </a-card>
          <a-card title="Patience" size="small" hoverable class="mt-1">
            <a-input-number
              v-model:value="trainingParams.patience"
              class="w-100"
              :min="0"
            />
          </a-card>
          <a-card title="Epochs" size="small" hoverable class="mt-1">
            <a-input-number
              v-model:value="trainingParams.epochs"
              class="w-100"
              :min="0"
            />
          </a-card>
        </a-card>

        <a-card title="Split Size" size="small" hoverable class="mt-3">
          <a-card
            title="Validation Split (%)"
            size="small"
            hoverable
            class="mt-1"
          >
            <a-input-number
              v-model:value="trainingParams.validation"
              class="w-100"
              :min="0"
              :max="100"
              :step="10"
            />
          </a-card>
        </a-card>
      </a-tab-pane>
    </a-tabs>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  inject: ['toast'],
  props: { showSetupTrainingModal: { type: Boolean } },
  emits: ['performTraining', 'setShowSetupTrainingModal'],

  data() {
    return {
      activeKey: '1',
      selectedYoloVersion: null,
      selectedYoloVariant: null,
      trainingDescription: null,
      requestTrainingLoading: false,
      yoloVersionOptions: [
        { label: 'Yolov5', value: 'yolov5' },
        { label: 'Yolov8', value: 'yolov8' },
      ],
      yoloVersionFive: ['nu', 'su', 'mu', 'lu', 'xu'],
      yoloVersionEight: ['n', 's', 'm', 'l', 'x'],
      trainingParams: {
        batchSize: 32,
        patience: 20,
        epochs: 100,
        validation: 20,
      },
    };
  },

  computed: {
    ...mapGetters(['isObjectRotationEnabled']),
    isSetupTrianingDisabled() {
      return !(
        this.selectedYoloVersion &&
        this.selectedYoloVariant &&
        this.trainingDescription
      );
    },
    filteredYoloVersionOptions() {
      if (this.isObjectRotationEnabled) {
        return this.yoloVersionOptions.filter(
          (option) => option.value === 'yolov8'
        );
      }
      return this.yoloVersionOptions;
    },

    isTrainingParamsDisabled() {
      return !(
        this.trainingParams.batchSize &&
        this.trainingParams.patience &&
        this.trainingParams.epochs
      );
    },

    yoloVariantOptions() {
      if (!this.selectedYoloVersion) return [];
      if (this.selectedYoloVersion.toLowerCase() === 'yolov5') {
        return this.yoloVersionFive.map((version) => {
          return { label: version, value: version };
        });
      } else {
        return this.yoloVersionEight.map((version) => {
          return { label: version, value: version };
        });
      }
    },
  },

  methods: {
    resetSetupTrainingModal() {
      this.requestTrainingLoading = false;
      this.selectedYoloVersion = null;
      this.selectedYoloVariant = null;
      this.trainingDescription = null;
      this.trainingParams = {
        batchSize: 32,
        patience: 20,
        epochs: 100,
        validation: 20,
      };
      this.closeModal();
    },

    closeModal() {
      this.$emit('setShowSetupTrainingModal', false);
    },

    async handleSetupTraining() {
      this.requestTrainingLoading = true;
      if (this.isSetupTrianingDisabled) {
        this.requestTrainingLoading = false;
        return this.toast.error(
          'Please configure parameters of Model Configuration.'
        );
      }

      const modelData = {
        yolo_version: this.selectedYoloVersion,
        yolo_variant: this.selectedYoloVariant,
        model_version_description: this.trainingDescription,
        batch_size: this.trainingParams.batchSize,
        patience: this.trainingParams.patience,
        epochs: this.trainingParams.epochs,
        validation: this.trainingParams.validation / 100,
      };
      this.$emit('performTraining', modelData);
      this.resetSetupTrainingModal();
    },
  },
};
</script>
