import SQSServices from '@/services/sqs';
import TaskService from '@/services/tasks';
import trainingStatuses from 'src/config/training-status-config.js';
import httpClient from 'src/service/httpClient';
import LRTService from 'src/services/longRunningTask';

export default class RequestTrainingModel {
  constructor(taskJson, organization, modelChoice) {
    this.taskJson = taskJson;
    this.organization = organization;
    this.modelChoice = modelChoice;
  }

  async sendSqsMsgForModelAD(data = {}) {
    let payload = {
      queueName: 'object-detection',
      sqsMessage: {
        task_id: this.taskJson.taskID,
        name: this.taskJson.name,
        organization: this.organization,
        model_choice: this.modelChoice,
      },
    };

    if (Object.keys(data).length)
      payload.sqsMessage = { ...payload.sqsMessage, ...data };

    const [error] = await SQSServices.sendSQSMessage(payload);
    if (error) {
      console.error('error:', error);
    }
  }

  async loadImagesForAnnotation(selectedTask) {
    const res = await httpClient.getData(
      `organization/task/annotation_image_list/${selectedTask}/`,
      false
    );
    if (res === 'error') {
      console.error('error in getting images');
      return null;
    }
    return res.results;
  }

  async countAnnotatedFrames(selectedTask) {
    const frames = await this.loadImagesForAnnotation(selectedTask);
    let count = 0;
    if (!frames || !Array.isArray(frames)) return;
    frames.forEach((img) => {
      if (img.path_to_annotations) {
        count++;
      }
    });

    return count;
  }

  async createLongRunningTaskId() {
    const payload = {
      task: this.taskJson.taskID,
      name: 'object-detection-task',
      organization: this.organization,
      status: 'pending',
    };
    const [error, data] = await LRTService.createLongRunningTasks(
      payload,
      true
    );
    if (error) return null;
    return data.id;
  }

  async requestTraining(selectedTask, data = {}) {
    let message = '';
    const model_choice = this.taskJson.training_params.model_choice;
    if (
      model_choice === 'Model AD' ||
      model_choice === 'Model ADF' ||
      model_choice === 'Model D'
    ) {
      const annotated_frames = await this.countAnnotatedFrames(selectedTask);

      if (annotated_frames < 5) {
        message = 'Please label atleast 5 frames';
        return { message: message, status: false };
      }
      const longRunningTaskId = await this.createLongRunningTaskId();
      if (!longRunningTaskId) {
        message = 'Failed to start model training.';
        return { message: message, status: false };
      }
      data.long_running_task_id = longRunningTaskId;
      await this.sendSqsMsgForModelAD(data);
    }

    const payload = {
      train_status: trainingStatuses.in_annotation,
    };
    const [error] = await TaskService.updateTrainedTask(selectedTask, payload);
    if (error) {
      message =
        error?.response?.data?.detail ||
        'Something went wrong, please try again later.';
      return { message: message, status: false };
    }
    message = 'Task has been sent for training.';
    return { message: message, status: true };
  }
}
